import React, { useState } from "react";
import { Collapse, ListGroup, ListGroupItem } from "reactstrap";
import blueZoneIcon from "../media/bluezones_logo_only.png";
import spicyIcon from "../media/icons8-chili-pepper-48.png";
import dickIcon from "../media/icons8-dick-64.png";
import heartIcon from "../media/icons8-medical-heart-48.png";
import halalIcon from "../media/icons8-halal-sign-50.png";
import bottleIcon from "../media/icons8-beer-bottle-48.png";

import { menu } from "../data/menu";
import Pie from "./Pie";
import NutBreakdown from "./NutBreakdown";

const Menu = () => {
  const [collapseStarters, setCollapseStarters] = useState({});
  const [collapseMains, setCollapseMains] = useState({});
  const [collapseDrinks, setCollapseDrinks] = useState({});

  const handleDrinkToggle = async key => {
    if (collapseDrinks[key]) {
      await setCollapseDrinks({
        ...collapseDrinks,
        [key]: false
      });
      console.log("Toggle", collapseDrinks);
    } else {
      await setCollapseDrinks({
        ...collapseDrinks,
        [key]: true
      });
      console.log("No toggle", collapseDrinks);
    }
  };
  const handleMainToggle = async key => {
    if (collapseMains[key]) {
      await setCollapseMains({
        ...collapseMains,
        [key]: false
      });
      console.log("Toggle", collapseMains);
    } else {
      await setCollapseMains({
        ...collapseMains,
        [key]: true
      });
      console.log("No toggle", collapseMains);
    }
  };

  const handleStarterToggle = async key => {
    if (collapseStarters[key]) {
      await setCollapseStarters({
        ...collapseStarters,
        [key]: false
      });
      console.log("Toggle", collapseStarters);
    } else {
      await setCollapseStarters({
        ...collapseStarters,
        [key]: true
      });
      console.log("No toggle", collapseStarters);
    }
  };

  const getIcon = icon => {
    switch (icon) {
      case "bluezone":
        return (
          <img
            src={blueZoneIcon}
            alt="Blue Zone Meal"
            height="32px"
            width="32px"
            title="Blue Zone Meal"
          />
        );
      case "booze":
        return (
          <img
            src={bottleIcon}
            alt="beer bottle"
            height="32px"
            width="32px"
            title="Contains alcohol, derp..."
          />
        );
      case "spicy":
        return (
          <img
            src={spicyIcon}
            alt="Spicy"
            title="This will burn your bung-hole"
            height="32px"
            width="32px"
          />
        );
      case "dicks":
        return (
          <img
            src={dickIcon}
            alt="Dick food"
            title="May contain dicks..."
            height="32px"
            width="32px"
          />
        );
      case "favorite":
        return (
          <img
            src={heartIcon}
            alt="Favorite food"
            title="Chef's coronary favorite"
            height="32px"
            width="32px"
          />
        );
      case "halal":
        return (
          <img
            src={halalIcon}
            alt="Halal food"
            title="Allah's choice!"
            height="32px"
            width="32px"
          />
        );
      default:
        break;
    }
  };

  const renderDrinks = () => {
    return menu.drinks.map(drink => {
      const key = menu.drinks.indexOf(drink);
      return (
        <ListGroupItem
          className="pl-0"
          key={key}
          onClick={() => handleDrinkToggle(key)}
          style={{ cursor: "pointer" }}
        >
          <div className="d-flex align-items-center">
            {collapseDrinks[key] ? (
              <i className="material-icons text-muted menu__item__arrow__expanded mr-1">
                chevron_right
              </i>
            ) : (
              <i className="material-icons text-muted menu__item__arrow__collapsed mr-1">
                chevron_right
              </i>
            )}
            <div className="d-flex flex-column mr-auto pr-2">
              <span className="lead">{drink.title}</span>
              <small>
                <em>{drink.description}</em>
              </small>
            </div>
            <div className="d-flex flex-column align-items-center flex-shrink-0">
              {getIcon(drink.icon)}
            </div>
          </div>
          {drink.nutrition ? (
            <Collapse
              className="text-center"
              id={"drinkCollapse" + key}
              isOpen={collapseDrinks[key]}
            >
              <div className="my-3">
                <Pie nutrition={drink.nutrition} />
                <NutBreakdown nutrition={drink.nutrition} />
              </div>
            </Collapse>
          ) : null}
        </ListGroupItem>
      );
    });
  };
  const renderMains = () => {
    return menu.mains.map(main => {
      const key = menu.mains.indexOf(main);
      return (
        <ListGroupItem
          className="pl-0"
          key={key}
          onClick={() => handleMainToggle(key)}
          style={{ cursor: "pointer" }}
        >
          <div className="d-flex align-items-center">
            {collapseMains[key] ? (
              <i className="material-icons text-muted menu__item__arrow__expanded mr-1">
                chevron_right
              </i>
            ) : (
              <i className="material-icons text-muted menu__item__arrow__collapsed mr-1">
                chevron_right
              </i>
            )}
            <div className="d-flex flex-column mr-auto pr-2">
              <span className="lead">{main.title}</span>
              <small>
                <em>{main.description}</em>
              </small>
            </div>
            <div className="flex-shrink-0">
              <div className="flex-shrink-0">{getIcon(main.icon)}</div>
            </div>
          </div>
          <Collapse
            className="text-center"
            id={"mainCollapse" + key}
            isOpen={collapseMains[key]}
          >
            <div className="my-3">
              <Pie nutrition={main.nutrition} />
              <NutBreakdown nutrition={main.nutrition} />
            </div>
          </Collapse>
        </ListGroupItem>
      );
    });
  };
  const renderStarters = () => {
    return menu.starters.map(starter => {
      const key = menu.starters.indexOf(starter);
      return (
        <ListGroupItem
          className="pl-0"
          key={key}
          onClick={() => handleStarterToggle(key)}
          style={{ cursor: "pointer" }}
        >
          <div className="d-flex align-items-center">
            {collapseStarters[key] ? (
              <i className="material-icons text-muted menu__item__arrow__expanded mr-1">
                chevron_right
              </i>
            ) : (
              <i className="material-icons text-muted menu__item__arrow__collapsed mr-1">
                chevron_right
              </i>
            )}
            <div className="d-flex flex-column mr-auto pr-2">
              <span className="lead">{starter.title}</span>
              <small>
                <em>{starter.description}</em>
              </small>
            </div>
            <div className="flex-shrink-0">
              <div className="flex-shrink-0">{getIcon(starter.icon)}</div>
            </div>
          </div>
          <Collapse
            className="text-center"
            id={"starterCollapse" + key}
            isOpen={collapseStarters[key]}
          >
            <div className="my-3">
              <Pie nutrition={starter.nutrition} />
              <NutBreakdown nutrition={starter.nutrition} />
            </div>
          </Collapse>
        </ListGroupItem>
      );
    });
  };

  return (
    <>
      <h3>Lite Bites</h3>
      <ListGroup flush>{renderStarters()}</ListGroup>
      <h3 className="mt-3">Mains</h3>
      <ListGroup flush>{renderMains()}</ListGroup>
      <h3 className="mt-3">Libations</h3>
      <ListGroup flush>{renderDrinks()}</ListGroup>
    </>
  );
};

export default Menu;
