import React from "react";
import { Doughnut } from "react-chartjs-2";

function Pie({ nutrition }) {
  const { carbs, fat, protein, booze } = nutrition;
  let data;
  if (booze) {
    data = {
      labels: ["Carbs", "Fat", "Protein", "Alcohol"],
      datasets: [
        {
          data: [carbs * 4, fat * 9, protein * 4, booze],
          backgroundColor: ["#5cb85c", "#d9534f", "#428bca", "#BF68F5"]
        }
      ]
    };
  } else {
    data = {
      labels: ["Carbs", "Fat", "Protein"],
      datasets: [
        {
          data: [carbs * 4, fat * 9, protein * 4],
          backgroundColor: ["#5cb85c", "#d9534f", "#428bca"]
        }
      ]
    };
  }
  return (
    <div
      className="d-flex justify-content-center w-100 mt-3"
      style={{ width: "300px", height: "150px" }}
    >
      <Doughnut data={data} options={{ maintainAspectRatio: false }} />
    </div>
  );
}

export default Pie;
