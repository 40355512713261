export const menu = {
  mains: [
    {
      title: "Jum's Red Door",
      description:
        "Inpired by the one-and-only Nam's of San Pedro.  Vietnamese yellow curry with salmon on a bed of brown, basmati rice.",
      icon: "spicy",
      nutrition: {
        cals: 601,
        carbs: 60,
        fat: 29,
        protein: 26
      }
    },
    {
      title: "The Green Granny",
      description:
        "Glorious greens tumbled with furtive Fuji apples, wonderous walnuts, and a crazy amount of cranberries tossed with a tizzy of Granny's semi-secret dressing.",
      icon: "favorite",
      nutrition: {
        cals: 561,
        carbs: 56,
        fat: 38,
        protein: 7
      }
    },
    {
      title: "Stir Fried Ahi Burrito",
      description:
        "Today's market tuna catch, wok and rolled amidst a bounty of fresh veg bathed a soy-based, aioli drizzle so chock full 'O umami that you might just have to call your abuela to explain why you're crying.  Served with our picante, pinapple Punalu'u salsa in a spinach serape.",
      icon: "bluezone",
      nutrition: {
        cals: 569,
        carbs: 42,
        fat: 27,
        protein: 42
      }
    }
  ],
  starters: [
    {
      title: "Amazeballs Avocado Toast",
      description:
        "Seasonal (i.e. Freshly-stolen from Koichi's tree in Manoa) and mashed to perfection.  Served on multi-grain toast with a scintillating, sea-salt sprinkle of Spike.  We eat Millennials for breakfast.",
      icon: "favorite",
      nutrition: {
        cals: 535,
        carbs: 32,
        fat: 45,
        protein: 9
      }
    },
    {
      title: "Diamond Wrap",
      description:
        "A mouth-watering mashup of smartly shredded summer squash and purple cabbage drizzled with a delectable, home-brewed ale-infused BBQ emulsion wrapped in a 27-grain faux-tortilla wrap.  This is your ticket to join the Seraphim.",
      icon: "bluezone",
      nutrition: {
        cals: 297,
        carbs: 58,
        fat: 5,
        protein: 10
      }
    },
    {
      title: "Hummus, Heaven Sent ",
      description:
        "Hand-shucked, Grosvenor garbanzos our signature Tantalus, grade-A tahini are BFFs when it comes to fire-roasted, Azthentic chipotle, one-full-head of adobe-kiln-roasted garlic, and a sturdy dose of pico de gallo.  Lay down your religion, and lap up this quasi-agnostic ad-mixture with our fresh-fired, stone-oven pita.",
      icon: "halal",
      nutrition: {
        cals: 367,
        carbs: 53,
        fat: 14,
        protein: 16
      }
    }
  ],
  drinks: [
    {
      title: "Olivia Porter",
      description:
        "The pinnacle of Nirvana in a pint glass.  This pairs with EVERYTHING!",
      icon: "booze",
      nutrition: {
        cals: 262,
        carbs: 9,
        fat: 0,
        protein: 1,
        booze: 262 - (9 * 4 + 1 * 4)
      }
    },
    {
      title: "Kahalu'u Tap Water",
      description:
        "Chemically confirmed as the best water west of Waipahu.  Get one free with every order of Olivia Porter!",
      icon: "none"
    }
  ]
};
