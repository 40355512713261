import React, { useState } from "react";
import slowFood from "../media/slow_food.mp4";
import { Button } from "reactstrap";
import MenuModal from "./MenuModal";

export default () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    return (
        <header>
            <video
                autoPlay="autoplay"
                className="d-none d-sm-block"
                id="background-video"
                loop="loop"
                media="all and (min-width: 576px)"
                muted="muted"
                playsInline="playsinline"
                preload="auto"
                style={{
                    backgroundColor: "black",
                    position: "fixed",
                    right: 0,
                    bottom: 0,
                    minWidth: "100%",
                    minHeight: "100%",
                }}
            >
                <source src={slowFood} type="video/mp4" />
            </video>
            <div className="mainWindow">
                <div className="d-flex flex-column container h-100 text-center align-items-center justify-content-center">
                    <div className="mb-5 text-light lead cities">
                        Chiang Mai &bull; Honolulu &bull; Los Angeles &bull;
                        Tokyo
                    </div>
                    <h1 className="display-3 mb-3 text-danger title">
                        Kimfusion
                    </h1>
                    <p className="lead mb-0 text-light definition">
                        <em>
                            Kimfusion noun kim·​fu·​sion | \ kim-ˈfyü-zhən \{" "}
                            <br />1 : an act or instance of combining culture,
                            flavour, and soul
                            <br />2 : the sensation of angels fornicating in
                            your mouth
                        </em>
                    </p>
                    <Button
                        className="d-block d-sm-none m-5"
                        color="danger"
                        onClick={() => setModalIsOpen(true)}
                        outline
                        size="sm"
                    >
                        Today's Menu
                    </Button>
                    <Button
                        className="d-none d-sm-block m-5"
                        color="light"
                        onClick={() => setModalIsOpen(true)}
                        outline
                    >
                        Today's Menu
                    </Button>
                </div>
            </div>
            <MenuModal
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
            />
        </header>
    );
};
