import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Menu from "./Menu";

const MenuModal = props => {
  const { modalIsOpen, setModalIsOpen } = props;
  const closeBtn = (
    <button className="close" onClick={() => setModalIsOpen(!modalIsOpen)}>
      <i className="material-icons">local_dining</i>
    </button>
  );

  return (
    <Modal contentClassName="bg-light" isOpen={modalIsOpen} size="lg">
      <ModalHeader close={closeBtn}>Today's Menu</ModalHeader>
      <ModalBody>
        <Menu />
      </ModalBody>
    </Modal>
  );
};

export default MenuModal;
