import React from "react";

const NutBreakdown = ({ nutrition }) => {
  const { cals, carbs, fat, protein, booze } = nutrition;
  if (booze) {
    return (
      <small className="mt-3">
        <strong>Total Calories: </strong> {cals} | <strong>Carbs: </strong>{" "}
        {carbs * 4} cals | <strong>Fat: </strong> {fat * 9} cals |{" "}
        <strong>Protein: </strong> {protein * 4} cals |{" "}
        <strong>Alcohol: </strong> {booze} cals
      </small>
    );
  } else {
    return (
      <small className="mt-3">
        <strong>Total Calories: </strong> {cals} | <strong>Carbs: </strong>{" "}
        {carbs * 4} cals | <strong>Fat: </strong> {fat * 9} cals |{" "}
        <strong>Protein: </strong> {protein * 4} cals
      </small>
    );
  }
};

export default NutBreakdown;
